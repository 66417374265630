var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid grid-cols-12 gap-2" },
    [
      _c(
        "vx-card",
        { staticClass: "col-span-12" },
        [
          _c("questionnaire-event-filters", {
            on: {
              questionnaireInput: _vm.clear,
              clearFilters: _vm.clear,
              fetch: _vm.reloadData,
              shutdownAUTOLOAD: _vm.shutdownAutoload,
            },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          }),
        ],
        1
      ),
      _vm.questionnaire
        ? _c(
            "vx-card",
            { staticClass: "col-span-12" },
            [
              _c("questionnaire-information", {
                attrs: { questionnaire: _vm.questionnaire },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.questionnaire
        ? _c(
            "vx-card",
            { staticClass: "col-span-12" },
            [
              _c("questionnaire-event-indicators", {
                attrs: { indicators: _vm.indicators },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.chartData
        ? _c(
            "vx-card",
            { staticClass: "col-span-12" },
            [
              _c("questionnaire-events-severity-graphic", {
                attrs: { data: _vm.chartData },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vx-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.questionnaire,
              expression: "questionnaire",
            },
          ],
          staticClass: "col-span-12",
        },
        [
          _c("grid", {
            ref: "grid",
            attrs: {
              service: _vm.questionnaireService,
              route_name: "anythinghere",
              route_grid_path: "/answers/events/grid",
              order_column: _vm.gridParams.column,
              defaultOrderColumn: "user_name",
              direction: _vm.gridParams.direction,
              sortKeys: {
                event_count: null,
                medium_time: null,
              },
              column_formats: {
                user_name: (name) => (name ? name : "< Não Cadastrado >"),
                medium_time: (seconds) => {
                  return seconds
                },
                event_count: (counts) => {
                  const low = _vm._.get(counts, "low", null)
                  const medium = _vm._.get(counts, "medium", null)
                  const high = _vm._.get(counts, "high", null)

                  let elementary = ""

                  if (low !== null)
                    elementary = elementary.concat(
                      `<label class='_event-low'>${low}</label>`
                    )
                  if (medium !== null)
                    elementary = elementary.concat(
                      `<label class='_event-medium'>${medium}</label>`
                    )
                  if (high !== null)
                    elementary = elementary.concat(
                      `<label class='_event-high'>${high}</label>`
                    )
                  return `<div class='flex flex-row gap-2'>${elementary}</div>`
                },
                finished_at: (finished_at) =>
                  finished_at !== null ? finished_at : "Em Andamento",
              },
              css_formats: {
                user_name: (name) => (name ? name : "text-warning"),
                presence: (name) => (name ? "text-success" : "text-danger"),
              },
              hide_actions: false,
              fetchOnMounted: false,
              showLoading: false,
            },
            on: {
              "update:order_column": function ($event) {
                return _vm.$set(_vm.gridParams, "column", $event)
              },
              "update:direction": function ($event) {
                return _vm.$set(_vm.gridParams, "direction", $event)
              },
              "grid-UPDATED": function ($event) {
                _vm.reloadingGrid = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function (actions) {
                  return [
                    _vm.questionnaireIsProctored &&
                    !_vm.questionnaireIsSessionFinished
                      ? _c("feather-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: `proctoring.monitor`,
                              expression: "`proctoring.monitor`",
                            },
                          ],
                          attrs: {
                            icon: "VideoIcon",
                            svgClasses:
                              "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openProctoringMonitor(actions.row)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("feather-icon", {
                      attrs: {
                        icon: "EyeIcon",
                        svgClasses:
                          "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openDetails(actions.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }